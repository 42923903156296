import React from "react"
import { isEmail } from "@formiz/validations"
import { FieldInput } from "../fields"
import { FormInputEmailData } from "../../../config/config-data";

const Step4 = () => {
  return (
    <div id="leadform_1662793850710">
      <FieldInput
        customID="email"
        type="email"
        name="Email"
        label="Email"
        required={FormInputEmailData.form_input_required ? `Warning: Your Email Is Required` : false}
        autoComplete="on"
        validations={[
          {
            rule: isEmail(),
            message: "Warning: This is not a valid email"
          }
        ]}
      />
      {FormInputEmailData.form_input_help_text &&
      <div className="Form_Card">
        <div className="Normal_Text">{FormInputEmailData.form_input_help_text}</div>
      </div>
      }
    </div>
  );
};

export default Step4;
